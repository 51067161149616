import { get, post } from './http'

export const lastDo = () => {
  const url = '/question/last_do'
  return get(url)
}

export const historyCount = (params) => {
  const url = '/question/history_count'
  return get(url, params)
}

export const look = (params) => {
  const url = '/question/look'
  return get(url, params)
}

export const rePost = (data) => {
  const url = '/question/re_post'
  return post(url, data)
}
