import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-111caba0"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "header"
};
var _hoisted_2 = {
  class: "title"
};
import router from '@/router';
export default {
  props: {
    title: String,
    name: String
  },
  setup: function setup(__props) {
    var props = __props;

    var handleBack = function handleBack(n) {
      if (n === '-1') {
        router.go('-1');
      } else {
        router.push({
          name: props.name
        });
      }
    };

    return function (_ctx, _cache) {
      var _component_arrow_left_bold = _resolveComponent("arrow-left-bold");

      var _component_el_icon = _resolveComponent("el-icon");

      var _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_button, {
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return handleBack(props.name);
        })
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_icon, null, {
            default: _withCtx(function () {
              return [_createVNode(_component_arrow_left_bold)];
            }),
            _: 1
          })];
        }),
        _: 1
      }), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(props.title), 1)])]);
    };
  }
};