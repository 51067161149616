import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, unref as _unref, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7a18fbff"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "question"
};
var _hoisted_2 = {
  class: "mt-header"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  class: "list-title"
};
var _hoisted_5 = {
  class: "count"
};
import { ref } from 'vue';
import { lastDo as lastDoApi } from '@/api/question';
import router from '@/router';
export default {
  setup: function setup(__props) {
    var subjects = ref([]);

    var init = function init() {
      lastDoApi().then(function (res) {
        subjects.value = res.subjects;
      }).catch();
    };

    init();
    return function (_ctx, _cache) {
      var _component_Header = _resolveComponent("Header");

      var _component_ArrowRight = _resolveComponent("ArrowRight");

      var _component_el_icon = _resolveComponent("el-icon");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Header, {
        title: "我的题库",
        name: "me"
      }), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subjects.value, function (item) {
        return _openBlock(), _createElementBlock("div", {
          class: "list",
          key: item.id,
          onClick: function onClick($event) {
            return _unref(router).push({
              name: 'question-detail',
              params: {
                id: item.id
              }
            });
          }
        }, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.subject), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(item.count), 1), _createVNode(_component_el_icon, null, {
          default: _withCtx(function () {
            return [_createVNode(_component_ArrowRight)];
          }),
          _: 1
        })])], 8, _hoisted_3);
      }), 128))])]);
    };
  }
};